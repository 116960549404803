<template>
  <div class="header mb-5">
    <v-app-bar app color="transparent" flat height="75">
      <v-container class="py-0 fill-height">
        <div class="d-flex align-center">
          <v-app-bar-nav-icon
            @click="$store.state.menu = !$store.state.menu"
            height="20"
            width="20"
            color="primary"
            class="me-4"
          ></v-app-bar-nav-icon>
        </div>
        <v-spacer> </v-spacer>
        <img width="120" class="logo" src="@/assets/logo.png" />
        <v-spacer> </v-spacer>
        <div class="d-flex align-center">
          <v-btn
            :href="`http://menyo.co/${$store.state.user_menu_dashboard.id}`"
            target="_blank"
            v-if="!is_admin"
            class="mx-3"
            color="white"
            small
            elevation="2"
            fab
          >
            <v-icon>visibility</v-icon>
          </v-btn>

          <v-btn
            v-if="!is_admin"
            class="me-3"
            @click="download_qr()"
            fab
            small
            elevation="2"
            download
          >
            <v-icon>qr_code_2</v-icon>
          </v-btn>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                tile
                class="rounded-lg header-menu btn-drop-down black--text d-block"
                elevation="1"
                v-bind="attrs"
                v-on="on"
                color="white"
              >
                <v-avatar size="40">
                  <img
                    :src="
                      $store.state.user_menu_dashboard.logo
                        ? `${$store.state.def_path}/${$store.state.user_menu_dashboard.logo}`
                        : require('@/assets/avatar.png')
                    "
                  />
                </v-avatar>
                <div class="d-none d-lg-block">
                  <h4 class="black--text ms-2 text-capitalize">
                    {{ $store.state.user_menu_dashboard.store_name }}
                  </h4>
                </div>
                <v-icon class="d-none d-md-block" right>
                  keyboard_arrow_down
                </v-icon>
              </v-btn>
            </template>
            <v-list dense class="menu-no-active">
              <v-list-item-group color="primary">
                <v-list-item @click="logout()">
                  <v-list-item-icon class="me-1">
                    <v-icon>logout</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>تسجيل الخروج </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>

            <!--/menu -->
          </v-menu>
        </div>
      </v-container>
    </v-app-bar>
    <qrcode
      class="d-none"
      :id="'qr_code'"
      :size="size"
      :value="qrText"
    ></qrcode>
  </div>
</template>

<style lang="scss" scoped>
::v-deep {
  .avatar {
    position: relative;
    top: 3px;
  }
  .v-toolbar__content {
    border-bottom: 1px solid #ddd;
  }
  .language {
    width: 50px;
    font-size: 13px;
    .v-select__selections {
      font-weight: bold;
      font-size: 14px;
      padding: 0;
    }
    fieldset {
      border: none !important;
    }
    .v-input__icon {
      display: none;
    }
    .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
      > .v-input__control
      > .v-input__slot,
    .v-text-field.v-text-field--enclosed .v-text-field__details {
      padding: 0;
    }
  }
  //---- language

  .lang-item {
    min-height: 28px !important;
    font-size: 12px !important;
  }
  .v-btn:not(.v-btn--round).v-size--default.header-menu {
    height: auto;
    padding: 3px 5px 3px 25px;
    @media (max-width: 991px) {
      padding: 5px;
      min-width: auto;
      height: 50px !important;
      width: 50px !important;
      border-radius: 50% !important;
    }
  }
}
</style>
<script>
import Qrcode from "v-qrcode/src/index";

export default {
  name: "AppHeader",
  data: () => ({
    size: 1000,

    lang: {
      val: {
        name: "Arbic",
        shortCut: "Ar",
        icon: "ar.png",
      },
      langs: [
        {
          id: 1,
          name: "Arbic",
          shortCut: "Ar",
          icon: "ar.png",
        },
        {
          id: 2,
          name: "English",
          shortCut: "En",
          icon: "en.png",
        },
      ],
    },
    items: [
      { text: "البروفيل", icon: "mdi-account" },
      { text: "تسجيل الخروج", icon: "logout" },
    ],
  }),
  components: {
    Qrcode,
  },
  computed: {
    is_admin() {
      return this.$store.state.user_menu_dashboard.is_admin == 1 ? true : false;
    },
    qrText() {
      return `http://menyo.co?menu=${this.$store.state.user_menu_dashboard.id}`;
    },
  },
  methods: {
    toggleDarkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
    download_qr() {
      var canvas = document.getElementById("qr_code").firstElementChild;
      var img = canvas.toDataURL("image/png");
      var link = document.createElement("a");
      link.download = "qr_code.png";
      link.href = img;
      link.click();
    },
    logout() {
      let vm = this;
      this.$store.commit("logout");
      setTimeout(() => {
        vm.$router.push({ name: "login" });
      }, 500);
    },
  },
};
</script>
